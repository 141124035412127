import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Body from '../components/Body';
import InputField from '../components/InputField';
import { useFlash } from '../contexts/FlashProvider';
import ReactGA from 'react-ga4'

// define the full API URL and fallback value
const REACT_APP_SMS_FULL_API_URL = process.env.REACT_APP_SMS_FULL_API_URL || 
'http://linode1.cocajola.xyz:5001/send-sms';

export default function SmsPage() {
    const [formErrors, setFormErrors] = useState({});
    const phoneField = useRef();
    const bodyField = useRef();
    const navigate = useNavigate();
    const flash = useFlash();

    // change focus to phoneField
    useEffect(() => {
        // change focus to phoneField
        if (phoneField.current) {
            phoneField.current.focus();
        }
    }, []);

    // submit form to send SMS
    const onSubmit = async (event) => {
        event.preventDefault();
        const phone = phoneField.current.value;
        const body = bodyField.current.value;

        // input validation
        const errors = {}
        if (!phone) {
            errors.phone = 'username must not be empty'
        }
        if (!body) {
            errors.body = 'password must not be empty'
        }
        setFormErrors(errors)
        if (errors.phone || errors.body) {
            return
        }

        try {
            const response = await fetch(REACT_APP_SMS_FULL_API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify({
                    to: phoneField.current.value,
                    body: bodyField.current.value,
                }),
            });
    
            if (!response.ok) {
                flash('SMS could not be sent. Please try again.', 'danger');
                ReactGA.event('sms_sent', { success: false });
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            setFormErrors({});
            flash('Your SMS has been sent successfully.', 'success');
            ReactGA.event('sms_sent', { success: true });
            navigate('/');
        } catch (error) {
            console.error('Error:', error);
            flash('SMS could not be sent. Please try again.', 'danger');
            ReactGA.event('sms_sent', { success: false });
        }
    };

    // return the SmsPage component code
    return (
        <Body>
            <h1>Your SMS</h1>
            <Form onSubmit={onSubmit}>
                <InputField
                    name="phone" placeholder="Phone Number"
                    error={formErrors.phone} fieldRef={phoneField} />
                <InputField
                    name="body" placeholder="SMS Content"
                    error={formErrors.body} fieldRef={bodyField} />
                <Button variant="primary" type="submit">Send SMS!</Button>
            </Form>
        </Body>
    );
}